import Vue from 'vue';
import App from './app.vue';
import router from './router/router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import './plugins/vuelidate';
import './styles/app.scss';
import 'roboto-fontface/css/roboto/sass/roboto-fontface.scss';

import '@/modules/global-components';

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App)
}).$mount('#app');
