import axios from 'axios';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from './index';
import { UiTemplate } from '@raccoon/shared';

export type SchemaType = { _id: string; displayName: string };

@Module({ name: 'TemplateModule', namespaced: true, dynamic: true, store })
export default class TemplateStore extends VuexModule {
    templates: UiTemplate[] | null = null;

    @Mutation
    setLoaded(templates: UiTemplate[]): void {
        this.templates = templates;
    }
    @Mutation
    updateLoaded(updatedTemplate: UiTemplate): void {
        if (!updatedTemplate) {
            return;
        }
        const foundTemplateIdx = this.templates?.findIndex((template) => template._id === updatedTemplate._id);

        if (foundTemplateIdx === undefined) {
            return;
        }

        if (foundTemplateIdx === -1) {
            this.templates?.push(updatedTemplate);
        } else {
            this.templates = this.templates ?? [];
            this.templates.splice(foundTemplateIdx, 1, updatedTemplate);
        }
    }

    @Action({ commit: 'setLoaded' })
    async load(): Promise<UiTemplate[] | null> {
        try {
            return await axios.get(`/api/templates`);
        } catch (err) {
            return [];
        }
    }

    @Action({ commit: 'updateLoaded' })
    async create({ displayName, resource, tenantDefinition }: { displayName: string; resource: string; tenantDefinition: string | null }): Promise<UiTemplate | null> {
        return await axios.post(`/api/templates`, { displayName, resource, tenantDefinition });
    }

    @Action({ commit: 'updateLoaded' })
    async update(template: UiTemplate): Promise<UiTemplate | null> {
        await axios.put(`/api/templates/${template._id}`, template);

        return template;
    }

    get getLoadedTemplateById(): (_id: string, createCopy: boolean) => UiTemplate | null {
        return (_id: string, createCopy = false): UiTemplate | null => {
            const foundTemplate = this.templates?.find((template) => template._id === _id);
            if (createCopy) {
                return JSON.parse(JSON.stringify(foundTemplate ?? null));
            } else {
                return foundTemplate ?? null;
            }
        };
    }
}
