export enum WorkflowState {
    /**
     * Frisch erstellt
     */
    Created,

    /**
     * Tut grad was
     */
    InProcess,

    /**
     * Wartet auf Manuelle Eingaben
     */
    Waiting,

    /**
     * Abgebrochen
     */
    Aborted,

    /**
     * Erfolgreich Abgeschlossen
     */
    Done,

    /**
     * Abgeschlossen, es gibt aber Warnungen
     */
    DoneWithWarnings,

    Error
}

export type UiWorkflowEntry = {
    _id: string;
    displayName: string;

    workflowName: string;
    tenantDefinition: string;

    user: string;
    sourceObject: string;

    state: WorkflowState;
    creationDate: number;
};

export type UiWorkflow = UiWorkflowEntry & {
    access: Array<string>;
    messageLog: Array<UiWorkflowMessageText | UiWorkflowMessageAction | UiWorkflowMessageState>;

    currentStage: string;
};

export type UiWorkflowMessageText = {
    author: string;
    text: string;
    type: 'comment' | 'action' | 'state';
    creationDate: number;
};

export type UiWorkflowMessageAction = UiWorkflowMessageText & {
    type: 'action';
    action: string;
    result?: unknown;
};
export type UiWorkflowMessageState = UiWorkflowMessageText & {
    type: 'state';
    newState: WorkflowState;
};
