import { getModule } from 'vuex-module-decorators';

import AppState from './app-state';
import Auth from './auth-module';
import User from './user-module';
import UserGroup from './usergroup-module';
import Schema from './schema-module';
import AccessControl from './access-control';
import Template from './template-module';
import Variables from './variable-module';
import Actions from './action-module';

export const appState = getModule(AppState);
export const auth = getModule(Auth);
export const user = getModule(User);
export const userGroup = getModule(UserGroup);
export const schema = getModule(Schema);
export const accessControl = getModule(AccessControl);
export const template = getModule(Template);
export const variables = getModule(Variables);
export const actions = getModule(Actions);

export default { appState, auth, user, userGroup, schema, accessControl, template, variables, actions };
