import VueRouter, { Route } from 'vue-router';
import bundle from '@/store/bundle';
export enum DependencyAutoFetchTypes {
    user = 'users',
    usergroup = 'usergroups',
    acl = 'access-control',
    permission = 'permissions',
    template = 'templates',
    variables = 'variables',
    fullSchema = 'full-schema'
}

export function initDependencyAutoFetch(router: VueRouter): void {
    router.afterEach((to: Route) => {
        const metaToQuery = to.matched.filter((record) => record.meta.requires);

        if (metaToQuery.length && to.meta) {
            const typesToQuery = metaToQuery.flatMap((record) => record.meta.requires);
            to.meta.dependencyFetch = to.meta.dependencyFetch || [];

            if (typesToQuery.includes(DependencyAutoFetchTypes.user)) {
                to.meta.dependencyFetch.push(!bundle.user.users && bundle.user.load());
            }

            if (typesToQuery.includes(DependencyAutoFetchTypes.usergroup)) {
                to.meta.dependencyFetch.push(!bundle.userGroup.userGroups && bundle.userGroup.loadUserGroups());
            }

            if (typesToQuery.includes(DependencyAutoFetchTypes.acl)) {
                to.meta.dependencyFetch.push(!bundle.accessControl.theACL && bundle.accessControl.loadAccessControl());
            }

            if (typesToQuery.includes(DependencyAutoFetchTypes.permission)) {
                to.meta.dependencyFetch.push(!bundle.accessControl.dataPermission && bundle.accessControl.loadDataPermissions());
            }

            if (typesToQuery.includes(DependencyAutoFetchTypes.template)) {
                to.meta.dependencyFetch.push(!bundle.template.templates && bundle.template.load());
            }

            if (typesToQuery.includes(DependencyAutoFetchTypes.variables)) {
                to.meta.dependencyFetch.push(!bundle.variables.globalVariables && bundle.variables.load());
            }

            if (typesToQuery.includes(DependencyAutoFetchTypes.fullSchema)) {
                to.meta.dependencyFetch.push(!bundle.schema.fullSchema && bundle.schema.loadFullSchema());
            }
        }
    });
}
