/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { DependencyAutoFetchTypes } from './route-guards';
import { RouteConfig } from 'vue-router';

export const definitionRoutes: RouteConfig[] = [{
    path: '/definitions/resources',
    name: 'ResourceDefinitionOverview',
    component: () => import(/* webpackChunkName: "definitions" */ '@/views/resource-definition/resource-definition-overview.vue'),
    meta: {
        requires: [DependencyAutoFetchTypes.fullSchema]
    }
},
{
    path: '/definitions/resources/:_id/:displayName',
    name: 'ResourceDefinitionEdit',
    component: () => import(/* webpackChunkName: "definitions" */ '@/views/resource-definition/resource-definition-edit.vue'),
    meta: {
        requires: [DependencyAutoFetchTypes.fullSchema, DependencyAutoFetchTypes.usergroup, DependencyAutoFetchTypes.user, DependencyAutoFetchTypes.variables] // user / usergroup needed for default value identity field; variables used for remote list fields
    }
},
{
    path: '/definitions/tenants',
    name: 'TenantDefinitionOverview',
    component: () => import(/* webpackChunkName: "definitions" */ '@/views/tenant-definition/tenant-definition-overview.vue'),
    meta: {
        requires: [DependencyAutoFetchTypes.fullSchema]
    }
},
{
    path: '/definitions/tenants/:_id/:displayName',
    name: 'TenantDefinitionEdit',
    component: () => import(/* webpackChunkName: "definitions" */ '@/views/tenant-definition/tenant-definition-edit.vue'),
    meta: {
        requires: [DependencyAutoFetchTypes.fullSchema, DependencyAutoFetchTypes.template, DependencyAutoFetchTypes.variables, DependencyAutoFetchTypes.user, DependencyAutoFetchTypes.usergroup]
    }
},
{
    path: '/definitions/templates',
    name: 'TemplateOverview',
    component: () => import(/* webpackChunkName: "definitions" */ '@/views/templates/template-overview.vue'),
    meta: {
        requires: [DependencyAutoFetchTypes.fullSchema, DependencyAutoFetchTypes.template]
    }
},
{
    path: '/definitions/templates/:_id/:displayName',
    name: 'TemplateEdit',
    component: () => import(/* webpackChunkName: "definitions" */ '@/views/templates/template-edit.vue'),
    meta: {
        requires: [DependencyAutoFetchTypes.fullSchema, DependencyAutoFetchTypes.template, DependencyAutoFetchTypes.user, DependencyAutoFetchTypes.usergroup]
    }
}];