// import { FieldType } from './schema';

export enum AgentStatus {
    Created,
    Active,
    Paused,
    Revoked
}

export type AgentCapabilities = Record<string, unknown>;

export type UiAgentDefinition = {
    _id: string;
    status: AgentStatus;
    displayName: string;
    capabilities: AgentCapabilities;
    meta?: Record<string, unknown>;
    validTo: number;
};

// export enum AgentStatus {
//     Created,
//     Registered,
//     Active,
//     Paused,
//     Revoked
// }

// export type UiAgentDefinition = {
//     _id: string;
//     status: AgentStatus;
//     displayName: string;
//     labels: string[];
//     meta: Record<string, unknown>;
//     actionCount: number;
// };

// export type UiAgentSchemaDefinition = {
//     agents: AgentSchema;
// };

// export type AgentSchema = Record<string, AgentDefinition>;

// export type AgentDefinition = {
//     _id: string;
//     name: string;
//     displayName: string;
//     labels: string[];

//     actions: Array<AgentActionDefinition>;
// };

// export type AgentActionDefinition = {
//     name: string;
//     displayName: string;
//     fields: Array<AgentActionField>;
// };

// export type AgentActionField = {
//     name: string;
//     displayName: string;
//     required: boolean;
//     multipleValues: boolean;
//     expectedType: FieldType;
//     defaultValue?: unknown;
// };
