// export enum ResourcePermissions {
//     None = 0,
//     View = 1,
//     Edit = View | 2,
//     Default = 0
// }

export enum FieldPermissions {
    None = 0,
    View = 1,
    Edit = View | 2,
    Default = None
}

export enum TenantPermission {
    None = 0,
    View = 1,
    Edit = View | 2,
    Share = Edit | 4,
    /**
     * Neue anlegen, löschen?
     */
    Administrate = Share | 8,
    Default = None
}

export enum TenantActionPermission {
    None = 0,
    Execute = 1,
    Default = None
}

export enum TenantWorkflowPermission {
    None = 0,
    Execute = 1,
    Default = None
}

export enum TenantAlternativeViewPermission {
    None = 0,
    View = 1,
    Default = None
}

/**
 * Represent the permissions for a given resource-definition
 *
 * @export
 * @interface ResourceAcl
 */
export interface ResourceAcl {
    fields: {
        [fieldPath: string]: IdentityAccess<FieldPermissions>;
    };
}

export interface TenantAcl {
    access: IdentityAccess<TenantPermission>;
    actions: {
        [action: string]: IdentityAccess<TenantActionPermission>;
    };
    workflows: {
        [workflow: string]: IdentityAccess<TenantWorkflowPermission>;
    };
    alternativeViews: {
        [view: string]: IdentityAccess<TenantAlternativeViewPermission>;
    };
}

export type IdentityAccess<T> = {
    [identity: string]: T;
};

export type AccessControlDefinition = {
    resources: {
        [resourceName: string]: ResourceAcl;
    };
    tenants: {
        [tenantName: string]: TenantAcl;
    };
};

export type ResourceDataPermission = {
    fields: {
        [fieldPath: string]: FieldPermissions;
    };
};
export type TenantDataPermission = {
    access: TenantPermission;
    actions: {
        [action: string]: TenantActionPermission;
    };
    workflows: {
        [workflow: string]: TenantWorkflowPermission;
    };
    alternativeViews: {
        [view: string]: TenantAlternativeViewPermission;
    };
};

export type IdentityDataPermission = {
    identity: string;
    memberships: Array<string>;
    resources: { [resourceName: string]: ResourceDataPermission };
    tenants: { [tenantName: string]: TenantDataPermission };
};
