import { FieldDefinition } from './schema';

export type DynamicQuery = DynamicQueryGroup;

export type DynamicQueryOperator =
    | '$eq'
    | '$gt'
    | '$gte'
    | '$in'
    | '$lt'
    | '$lte'
    | '$ne'
    | '$nin'
    | '$exists'
    | '$regex'
    | '$all' //array
    | '$elemMatch' //array
    | '$size'; //array

export const DynamicQueryListOperators = ['$in', '$nin', '$all', '$elemMatch'];

export interface DynamicQueryField extends FieldDefinition {
    operators: Array<DynamicQueryOperator>;
    fullPath: string;
    resourceName: string;
}

export interface DynamicQueryCondition {
    field: string;
    operator: string;
    value: unknown;
}

export type LogicOperator = '$and' | '$or' | '$not' | '$nor';

export interface DynamicQueryGroup {
    logicOperator: LogicOperator;
    conditions: Array<DynamicQueryCondition | DynamicQueryGroup>;
}

export interface DynamicQueryItem extends DynamicQueryCondition, DynamicQueryGroup {}
