import axios from 'axios';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { UiLlamaTenant, UiUser } from '@raccoon/shared';
import store from './index';

@Module({ name: 'AuthModule', namespaced: true, dynamic: true, store })
export default class AuthStore extends VuexModule {
    activeUser: UiUser | null = null;
    activeTenant: UiLlamaTenant | null = null;

    @Mutation
    setActive({ user, tenant }: { user: UiUser; tenant: UiLlamaTenant }): void {
        this.activeUser = user;
        this.activeTenant = tenant;
    }

    @Action({ commit: 'setActive' })
    async loadActiveUser(): Promise<UiUser | null> {
        return await axios.get('/api/users/me');
    }
}
