import { FieldPermissions, TenantActionPermission, TenantAlternativeViewPermission, TenantPermission, TenantWorkflowPermission } from './access-control';

export interface SchemaDefinition {
    resources: ResourceSchema;
    tenants: TenantSchema;
}

export type ResourceSchema = Record<string, ResourceDefinition>;
export type TenantSchema = Record<string, TenantDefinition>;

export type ResourceDefinition = {
    /**
     * Technical name of the Type. Same as key in {@link SchemaDefinition}
     *
     * @type {string}
     */
    name: string;

    /**
     * DisplayName of the type
     *
     * @type {string}
     */
    displayName: string;
    deleted?: boolean;

    /**
     * All fields available for the type keyed by their fullPath
     *
     * @type {Object.<string, FieldDefinition>}
     * @example 'custom.myField': { ... }
     */
    fields: {
        [fullFieldPath: string]: FieldDefinition;
    };
};

export type AlternativeTenantView = {
    name: string;
    displayName: string;

    types: string[];
    parentRelations: Record<string, string | null>;
    relationProperties: Record<string, string>;
    deleted?: boolean;
};

export type TenantDefinitionTypeSettings = {
    createOnlyViaTemplate: boolean;
    overrideProhibited: boolean;

    onCreateAction: Array<string>;
    onUpdateAction: Array<string>;
    onDeleteAction: Array<string>;
};

export type TenantDefinition = {
    name: string;
    displayName: string;

    types: string[];
    parentRelations: Record<string, string | null>;
    typeSettings: Record<string, TenantDefinitionTypeSettings>;

    actions: ActionDefinition[];
    workflows: WorkflowDefinition[];
    alternativeViews: Record<string, AlternativeTenantView>;
    deleted?: boolean;
    search: string[];
};

export type FieldDefinition<T extends FieldOptions = FieldOptions> = {
    /**
     * Technical name of the field. Will be a sanitized version of the DisplayName
     *
     * @type {string} e.g. 'tenant'
     */
    name: string;

    /**
     * Path of the field
     *
     * @type {string} e.g. 'custom'
     * @default ''
     */
    path: string;

    /**
     * DisplayName of the field shown to the user
     *
     * @type {string} e.g. 'Version (^_^)'
     */
    displayName: string;

    exportName?: string;

    groupName?: string;

    sort?: number;

    deleted?: boolean;

    /**
     * The type of the Field
     *
     * @type {FieldType}
     */
    inputType: FieldType;

    predefined: boolean;

    description: '';

    renderWidth: 'default' | 'double' | 'full-width';

    /**
     * Options for the specific inputType
     *
     * @type {FieldOptions}
     */
    options: T;
};

export type FieldType = 'text' | 'number' | 'date' | 'identity' | 'list' | 'remote' | 'file' | 'boolean' | 'password' | 'link' | 'reference' | 'calculated' | 'license';

export type FieldOptions =
    | TextFieldOptions
    | NumberFieldOptions
    | DateFieldOptions
    | IdentityFieldOptions
    | ListFieldOptions
    | RemoteListFieldOptions
    | FileFieldOptions
    | BooleanFieldOptions
    | PasswordFieldOptions
    | LinkFieldOptions
    | ReferenceFieldOptions
    | CalculatedFieldOptions
    | LicenseFieldOptions;

export interface DefaultFieldOptions<T> {
    required: boolean;
    multiValues: boolean;
    readonly: boolean;

    default?: T | T[];
}

export interface UniqueValueOption {
    uniqueValues: boolean
}

export interface PatternValidatableOptions {
    validation?: { regex: string; flags: string };
}

export type TextStyle = 'single-line' | 'multi-line';

export interface TextFieldOptions extends DefaultFieldOptions<string>, PatternValidatableOptions, UniqueValueOption {
    textStyle: TextStyle;
}

export interface NumberFieldOptions extends DefaultFieldOptions<number>, UniqueValueOption {
    lessThan?: number;
    moreThan?: number;
}

export interface DateFieldOptions extends DefaultFieldOptions<string>, UniqueValueOption {
    lessThan?: string;
    moreThan?: string;
}

export type IdentityMode = 'user' | 'group';

export interface IdentityFieldOptions extends DefaultFieldOptions<string>, UniqueValueOption {
    identityMode: IdentityMode;
}

export type ListFieldItem = {
    displayName: string;
    displayOrder: number;
};

export interface ListFieldOptions extends DefaultFieldOptions<string>, UniqueValueOption {
    customKeyValues: boolean;

    listItems: {
        [key: string]: ListFieldItem;
    };
}

export type HttpVerb = 'GET' | 'PUT' | 'POST' | 'DELETE' | 'OPTIONS' | 'PATCH';
// export type RemoteResponseType = 'json' | 'text';
export type AuthenticationType = 'none' | 'basic' | 'token' | 'oauth2/clientcredentials';
export type HttpHeader = {
    key: string;
    value: unknown;
};
export type HttpContentType = 'none' | 'application/json' | 'text/plain' | 'application/xml';
export type UpdateFrequency = 'weekly' | 'hourly' | 'daily' | 'onsave';

export interface RemoteListFieldOptions extends Omit<ListFieldOptions, 'readonly' | 'default'>, UniqueValueOption {
    url: string;
    headers: HttpHeader[];
    body: string;
    contentType: HttpContentType;
    auth: {
        type: AuthenticationType;
        [key: string]: any;
    };
    verb: HttpVerb;
    responseTransformer: string;
    updateFrequency: UpdateFrequency;
    lastUpdate: number | null;
    cronJob?: string;
}

export interface FileFieldOptions {
    required: boolean;
    multiValues: boolean;
    allowedExtensions: string[];
}

export interface BooleanFieldOptions {
    required: boolean;
    readonly: boolean;

    default?: boolean;
}

export interface CalculatedFieldOptions {
    inputType: 'text' | 'link';
    multiValues: boolean;

    default?: string | Array<string>;
}

export interface PasswordFieldOptions {
    required: boolean;
}

export interface LicenseFieldOptions {
    required: boolean;
    defaultProductId: number;
}

export type LinkType = 'url' | 'tel' | 'mailto';

export interface LinkFieldOptions extends DefaultFieldOptions<string> {
    type: LinkType;
}

export interface ReferenceFieldOptions extends UniqueValueOption {
    required: boolean;
    multiValues: boolean;
    resourceType: string;
}

export enum ActionType {
    GenericHttpRequest = 'http-request',
    Debug = 'debug',
    Gitlab = 'gitlab',
    CreateFile = 'create-file',
    Aks = 'aks'
}

export const ActionsWithImmediateResponse: Record<ActionType, boolean> = {
    [ActionType.CreateFile]: true,
    [ActionType.Gitlab]: false,
    [ActionType.Debug]: false,
    [ActionType.GenericHttpRequest]: false,
    [ActionType.Aks]: false
};

export type ActionOptions = HttpRequestActionOptions | DebugActionOptions | FileCreateActionOptions | GitlabActionOptions | AksActionOptions;

export interface ActionDefinition<T extends ActionOptions = ActionOptions> {
    name: string;
    displayName: string;

    resource: string;
    actionType: ActionType;
    /**
     * E.g <type>.custom.passwordfield
     *
     * @type {string[]}
     * @memberof ActionDefinition
     */
    requiredFields: string[];
    deleted?: boolean;
    confirmBeforeExecute: boolean;
    options: T;
}

export function findAvailableName(currentNames: string[], plannedName: string): string {
    const technicalSafeName = makeNameSafeInternal(plannedName);
    const baseName = technicalSafeName;

    if (!itemExists(baseName)) {
        return technicalSafeName;
    }

    for (let id = 1; ; id++) {
        const name = id;
        if (!itemExists(baseName + name)) {
            return technicalSafeName + id;
        }
    }

    function itemExists(name: string): boolean {
        if (!currentNames) {
            return false;
        }
        return currentNames.includes(name);
    }
}

export function makeNameSafeInternal(name: string): string {
    const dashed = name.replace(/[^a-zA-Z0-9-]/g, '-').toLowerCase();
    return dashed;
}

export function buildFieldPath(fieldDef: FieldDefinition): string {
    if (!fieldDef.path) {
        return fieldDef.name;
    }

    return fieldDef.path + '.' + fieldDef.name;
}

type PlainTextOrPasswordValue = string | { provider: string; length: number; identifier: number; newValue?: string };

export type HttpRequestActionOptions = {
    url: string;
    headers: HttpHeader[];
    body: string;
    contentType: HttpContentType;
    auth: {
        type: AuthenticationType;
        [key: string]: any;
    };
    verb: HttpVerb;
    responseTransformer: string;
};

export type DebugActionOptions = {
    debugTokens: string;
    debugTemplate: string;
};

export type FileCreateActionOptions = {
    template: string;
    filename: string;
    mimeType: string;
};

export type GitlabActionOptions = {
    apiToken: PlainTextOrPasswordValue;
    baseUrl?: string;
    resourceType: 'pipeline';
    parameters: GitlabPipelineParameters;
};

export type GitlabPipelineParameters = {
    id: number | string;
    ref: string;
    variables?: Array<{
        key: string;
        variable_type: 'env_var' | 'file';
        value: string;
    }>;
};

export type AksActionOptions = {
    credentials: {
        apiToken: PlainTextOrPasswordValue;
        apiUrl: string;
    };
    envVariables: Array<string>;
    executionType: string;
};

export enum WorkflowActionType {
    Automatic,
    Manual
}

export interface WorkflowStageDefinition {
    name: string;
    displayName: string;

    type: WorkflowActionType;

    // Welche Gruppe/Identitäten darf denn diese Aktion ausführen
    access: Array<string>;
    // Optionale Tenantdefinition actions
    actions?: Array<ActionDefinition>;

    availableWhenState?: Array<string>;

    onSuccessStage: string;
    onErrorStage?: string;
}

export interface WorkflowDefinition {
    name: string;
    displayName: string;

    /*
     * Wer darf diesen Workflow ausführen
     */
    access: Array<string>;

    resource: string;

    // Über type werden die Actions geholt
    type: string;
    deleted?: boolean;

    stages: WorkflowStageDefinition[];
}

export type UiSchemaDefinition = {
    resources: UiResourceSchema;
    tenants: UiTenantSchema;
};
export type UiResourceSchema = Record<string, UiResourceDefinition>;
export type UiTenantSchema = Record<string, UiTenantDefinition>;

export type UiResourceDefinition = {
    /**
     * Technical name of the Type. Same as key in {@link SchemaDefinition}
     *
     * @type {string}
     */
    name: string;

    /**
     * DisplayName of the type
     *
     * @type {string}
     */
    displayName: string;

    deleted?: boolean;

    /**
     * All fields available for the type keyed by their fullPath
     *
     * @type {Object.<string, FieldDefinition>}
     * @example 'custom.myField': { ... }
     */
    fields: {
        [fullFieldPath: string]: WithPermission<FieldDefinition, FieldPermissions>;
    };
};

export type UiTenantDefinition = WithPermission<
    {
        name: string;
        displayName: string;

        types: string[];
        parentRelations: Record<string, string | null>;
        typeSettings: Record<string, TenantDefinitionTypeSettings>;

        actions: WithPermission<ActionDefinition, TenantActionPermission>[];
        workflows: WithPermission<WorkflowDefinition, TenantWorkflowPermission>[];

        alternativeViews: Record<string, WithPermission<AlternativeTenantView, TenantAlternativeViewPermission>>;
        deleted?: boolean;
        search: string[];
    },
    TenantPermission
>;
export type WithPermission<A extends any, B extends number> = A & { permission: B };

type FieldDefinitionContainer = {
    fields: Record<string, FieldDefinition>;
};

export function ensureShownFieldsAreInitialized(obj: any = {}, fieldDefinitionContainer: FieldDefinitionContainer, ignoreDefaultValue = false): any {
    for (const fieldPath in fieldDefinitionContainer.fields) {
        if (Object.prototype.hasOwnProperty.call(fieldDefinitionContainer.fields, fieldPath)) {
            const fieldDef = fieldDefinitionContainer.fields[fieldPath];

            if (fieldDef.deleted) continue;

            let ctx = obj;
            if (fieldDef.path) {
                ctx = obj[fieldDef.path] = obj[fieldDef.path] || {};
            }

            if (typeof ctx[fieldDef.name] !== 'undefined') {
                continue;
            }

            let defaultValue: unknown = undefined;

            if ('default' in fieldDef.options && fieldDef.options.default !== undefined && !ignoreDefaultValue) {
                defaultValue = fieldDef.options.default;
            }

            // if (fieldDef.options.required) {
            if ('multiValues' in fieldDef.options && fieldDef.options.multiValues) {
                ctx[fieldDef.name] = defaultValue ?? [];
            } else if (defaultValue !== undefined) {
                ctx[fieldDef.name] = defaultValue;
            }

            if (fieldDef.inputType === 'password') {
                ctx[fieldDef.name] = { newValue: defaultValue ?? '' };
            }

            if (fieldDef.inputType === 'license') {
                const options = fieldDef.options as LicenseFieldOptions;

                ctx[fieldDef.name] = { productId: options.defaultProductId ? options.defaultProductId : undefined };
            }
            // }
        }
    }

    return obj;
}

export function getFieldPath(fieldDef: FieldDefinition): string {
    if (fieldDef.path) {
        return `${fieldDef.path}.${fieldDef.name}`;
    } else {
        return fieldDef.name;
    }
}

export function getParentResources(parentRelations: Record<string, string | null>, sourceResourceName: string, sortDirection: 'resource-to-root' | 'root-to-resource' = 'resource-to-root'): Array<string> {
    const sortedParentHierarchy: Array<string> = [];
    let nextParent: string | null = parentRelations[sourceResourceName];

    while (nextParent) {
        if (sortDirection === 'resource-to-root') {
            sortedParentHierarchy.unshift(nextParent);
        } else {
            sortedParentHierarchy.push(nextParent);
        }

        nextParent = parentRelations[nextParent];
    }

    return sortedParentHierarchy;
}

export function getChildResources(parentRelations: Record<string, string | null>, sourceResourceName: string): Array<string> {
    const sortedChildHierarchy: Array<string> = [];

    const reverseRelations: Record<string, Array<string>> = {};

    for (const [childResource, parentResource] of Object.entries(parentRelations)) {
        if (parentResource) {
            reverseRelations[parentResource] ??= [];

            reverseRelations[parentResource].push(childResource);
        }
    }

    (function walkChildren(theChildren: Array<string> | undefined) {
        if (theChildren) {
            sortedChildHierarchy.push(...theChildren);

            for (const childResource of theChildren) {
                walkChildren(reverseRelations[childResource]);
            }
        }
    })(reverseRelations[sourceResourceName]);

    return sortedChildHierarchy;
}
