export enum UserRoles {
    admin = 'admin',
    maintainer = 'maintainer',
    editor = 'editor'
}

export interface RaccoonUser {
    _id: string;
    email: string;
    displayName: string;
    tenant: string;
    roles: {
        admin: boolean;
        maintainer: boolean;
        editor: boolean;
    };
    authHash: string;
    language: string;
    hasOriginalPassword: boolean;
    [prop: string]: unknown;
}

export interface UiUser {
    _id: string;
    displayName: string;
    email: string;
    tenant: string;
    roles: {
        admin: boolean;
        maintainer: boolean;
        editor: boolean;
    };
    language: string;
    avatar?: string;
    hideTenantNodeTypes: boolean;
    [prop: string]: unknown;
}

export interface UiLlamaTenant {
    _id: string;
    name: string;
    displayName: string;
    features: Record<string, boolean>;
    settings?: LlamaTenantSettings;
}

export type SfLogToTenantMapping = {
    tenantResourceName: string;
    tenantResourceMappingField: string;
    providerResourceName: string;
    providerResourceMappingField: string;
};

export interface LlamaTenantSettings {
    'sf-logging-to-tenantdefintion-mapping'?: Record<string, SfLogToTenantMapping>;
    'sf-logging-responsibility-group-mapping'?: {
        '0': string[];
        '1': string[];
        '2': string[];
    };
    [prop: string]: unknown;
}
