import type { LlamaTenant } from '@llama/ts-multitenancy';

export interface TenantRecord extends LlamaTenant {
    features?: Record<string, boolean>;
    settings?: Record<string, unknown>;
}

export enum TenantFeature {
    TenantDefinitionWorkflows = 'TenantDefinitionWorkflows',
    TenantDefinitionAlternativeViews = 'TenantDefinitionAlternativeViews',
    TenantBulkActions = 'TenantBulkActions'
}
