import axios from 'axios';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { UiUser } from '@raccoon/shared';
import store from './index';

@Module({ name: 'UserModule', namespaced: true, dynamic: true, store })
export default class UserStore extends VuexModule {
    users: UiUser[] | null = null;
    usersAndApiClients: UiUser[] | null = null;

    @Mutation
    setLoadedUsers(users: UiUser[]): void {
        this.usersAndApiClients = users;
        this.users = users.filter(user => !user.isApiClient);
    }

    @Mutation
    updateStoredUser(user: UiUser): void {
        if (this.users === null) {
            this.users = [user];
        } else {
            const index = this.users.findIndex((x) => x._id === user._id);

            if (index !== -1 && index !== undefined) {
                this.users.splice(index, 1, user);
            } else {
                this.users.push(user);
            }
        }
    }

    @Action({ commit: 'setLoadedUsers' })
    async load(): Promise<UiUser[] | null> {
        try {
            return await axios.get(`/api/users/`);
        } catch (error) {
            return null;
        }
    }

    @Action({ commit: 'updateStoredUser' })
    async update(user: UiUser): Promise<UiUser | null> {
        try {
            await axios.put(`/api/users/${user._id}`, {
                email: user.email,
                displayName: user.displayName,
                roles: user.roles
            });

            return user;
        } catch (error) {
            return null;
        }
    }

    get getLoadedUserById(): (_id: string) => UiUser | null {
        return (_id: string): UiUser | null => {
            return this.usersAndApiClients?.find((user) => user._id === _id) ?? null;
        };
    }
}
