import { DependencyAutoFetchTypes } from './route-guards';
import { RouteConfig } from 'vue-router';

export const tenantRoutes: RouteConfig[] = [
    {
        path: '/tenants/:tenantDefinitionId/:tenantDefinitionDisplayName/actions',
        name: 'TenantActionOverview',
        component: () => import(/* webpackChunkName: "tenant" */ '@/views/tenant/tenant-action-overview.vue'),
        meta: {
            requires: [DependencyAutoFetchTypes.user]
        }
    },
    {
        path: '/tenants/:tenantDefinitionId/:tenantDefinitionDisplayName/workflows',
        name: 'TenantWorkflowOverview',
        component: () => import(/* webpackChunkName: "tenant" */ '@/views/tenant/tenant-workflow-overview.vue'),
        meta: {
            requires: [DependencyAutoFetchTypes.user]
        }
    },
    {
        path: '/tenants/:tenantDefinitionId/:tenantDefinitionDisplayName/:obj?',
        name: 'TenantOverview',
        component: () => import(/* webpackChunkName: "tenant" */ '@/views/tenant/tenant-overview.vue'),
        meta: {
            requires: [DependencyAutoFetchTypes.template, DependencyAutoFetchTypes.user, DependencyAutoFetchTypes.usergroup] // user/usergroup must be loaded for identity fields
        }
    },
    {
        path: '/tenants/:tenantDefinitionId/:tenantDefinitionDisplayName/workflows/:_id',
        name: 'TenantWorkflowDetails',
        component: () => import(/* webpackChunkName: "tenant" */ '@/views/tenant/tenant-workflow-details.vue'),
        meta: {
            requires: [DependencyAutoFetchTypes.user, DependencyAutoFetchTypes.usergroup]
        }
    }
];
