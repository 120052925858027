import axios from 'axios';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AccessControlDefinition, IdentityDataPermission } from '@raccoon/shared';
import store from './index';

@Module({ name: 'AccessControlModule', namespaced: true, dynamic: true, store })
export default class AccessControlStore extends VuexModule {
    theACL: AccessControlDefinition | null = null;
    dataPermission: IdentityDataPermission | null = null;

    @Mutation
    setACL(acl: AccessControlDefinition): void {
        this.theACL = acl;
    }

    @Action({ commit: 'setACL' })
    async loadAccessControl(): Promise<AccessControlDefinition | null> {
        try {
            return await axios.get('/api/admin/access-control');
        } catch (error) {
            return null;
        }
    }

    @Mutation
    setDataPermissions(acl: IdentityDataPermission): void {
        this.dataPermission = acl;
    }

    @Action({ commit: 'setDataPermissions' })
    async loadDataPermissions(): Promise<IdentityDataPermission | null> {
        try {
            return await axios.get('/api/access-control');
        } catch (error) {
            return null;
        }
    }
}
