import axios from 'axios';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { UiVariableDefinition } from '@raccoon/shared';
import store from './index';

@Module({ name: 'VariableModule', namespaced: true, dynamic: true, store })
export default class VariableModule extends VuexModule {
    globalVariables: UiVariableDefinition[] | null = null;

    @Mutation
    setGlobalVariables(variables: UiVariableDefinition[]): void {
        this.globalVariables = variables;
    }

    @Mutation
    updateLoaded(updatedDefinition: UiVariableDefinition): void {
        if (!updatedDefinition) {
            return;
        }
        this.globalVariables = this.globalVariables ?? [];

        const foundVariableIdx = this.globalVariables?.findIndex((template) => template._id === updatedDefinition._id);

        if (foundVariableIdx === -1 || foundVariableIdx === undefined) {
            this.globalVariables?.push(updatedDefinition);
        } else {
            this.globalVariables.splice(foundVariableIdx, 1, updatedDefinition);
        }
    }

    @Mutation
    removeDefinition(updatedDefinition: UiVariableDefinition): void {
        if (!this.globalVariables) {
            return;
        }

        const foundVariableIdx = this.globalVariables?.findIndex((template) => template._id === updatedDefinition._id);

        if (foundVariableIdx > -1) {
            this.globalVariables?.splice(foundVariableIdx, 1);
        }
    }

    @Action({ commit: 'setGlobalVariables' })
    async load(): Promise<UiVariableDefinition[]> {
        return await axios.get(`/api/variables/global`);
    }

    @Action({ commit: 'updateLoaded' })
    async create(newVariableDefinition: Omit<UiVariableDefinition, '_id' | 'name'>): Promise<UiVariableDefinition> {
        return await axios.post(`/api/variables`, newVariableDefinition);
    }

    @Action({ commit: 'updateLoaded' })
    async update(variableDefinition: UiVariableDefinition): Promise<UiVariableDefinition> {
        return await axios.put(`/api/variables/${variableDefinition._id}`, {
            displayName: variableDefinition.displayName,
            type: variableDefinition.type,
            value: variableDefinition.value,
            availableFor: variableDefinition.availableFor,
            source: variableDefinition.source,
            options: variableDefinition.options
        });
    }

    @Action({ commit: 'removeDefinition' })
    async deleteDefinition(variableDefinition: UiVariableDefinition): Promise<UiVariableDefinition> {
        await axios.delete(`/api/variables/${variableDefinition._id}`);
        return variableDefinition;
    }
}
