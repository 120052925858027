import axios from 'axios';
import Axios from 'axios';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { FieldDefinition, ResourceDefinition, SchemaDefinition, TenantDefinition, UiResourceDefinition, UiSchemaDefinition, UiTenantDefinition } from '@raccoon/shared';
import store from './index';

export type SchemaType = { _id: string; displayName: string };

@Module({ name: 'SchemaModule', namespaced: true, dynamic: true, store })
export default class SchemaStore extends VuexModule {
    theSchema: UiSchemaDefinition | null = null;
    fullSchema: SchemaDefinition | null = null;

    @Mutation
    setSchema(newSchema: UiSchemaDefinition): void {
        if (newSchema) {
            this.theSchema = newSchema;
        }
    }
    @Mutation
    setFullSchema(newSchema: SchemaDefinition): void {
        if (newSchema) {
            this.fullSchema = newSchema;
        }
    }

    @Action({ commit: 'setSchema' })
    async loadSchema(): Promise<UiSchemaDefinition> {
        return await axios.get('/api/schema');
    }

    @Action({ commit: 'setFullSchema' })
    async loadFullSchema(): Promise<UiSchemaDefinition> {
        return await axios.get('/api/schema/full');
    }

    @Action({ commit: 'setFullSchema' })
    async addResourceType(typeName: string): Promise<SchemaDefinition> {
        const response = await axios.post<SchemaDefinition, SchemaDefinition>(`/api/schema/resources/${encodeURIComponent(typeName)}`);

        this.loadSchema(); // reload user schema without waiting for it
        return response;
    }

    @Action({ commit: 'setFullSchema' })
    async addTenantDefinition(typeName: string): Promise<SchemaDefinition> {
        const response = await axios.post<SchemaDefinition, SchemaDefinition>(`/api/schema/tenants/${encodeURIComponent(typeName)}`);

        this.loadSchema(); // reload user schema without waiting for it
        return response;
    }

    @Action({ commit: 'setFullSchema' })
    async addFieldDefinition({ type, field }: { type: string; field: FieldDefinition }): Promise<SchemaDefinition> {
        const response = await axios.post<unknown, SchemaDefinition>(`/api/schema/resources/${type}/fields`, {
            path: field.path,
            displayName: field.displayName,
            exportName: field.exportName,
            inputType: field.inputType,
            predefined: field.predefined,
            options: field.options,
            sort: field.sort,
            groupName: field.groupName
        });

        this.loadSchema(); // reload user schema without waiting for it
        return response;
    }

    @Action({ commit: 'setFullSchema' })
    async updateFieldDefinition({ typeName, fieldPath, fieldDef }: { typeName: string; fieldPath: string; fieldDef: FieldDefinition }): Promise<SchemaDefinition> {
        const response = await axios.put<FieldDefinition, SchemaDefinition>(`/api/schema/resources/${typeName}/fields/${fieldPath}`, {
            path: fieldDef.path,
            name: fieldDef.name,
            displayName: fieldDef.displayName,
            exportName: fieldDef.exportName,
            inputType: fieldDef.inputType,
            predefined: fieldDef.predefined,
            options: fieldDef.options,
            sort: fieldDef.sort,
            groupName: fieldDef.groupName,
            description: fieldDef.description,
            renderWidth: fieldDef.renderWidth
        });

        this.loadSchema(); // reload user schema without waiting for it
        return response;
    }

    @Action({ commit: 'setFullSchema' })
    async updateResourceDefinition({ typeName, resourceDef }: { typeName: string; resourceDef: ResourceDefinition }): Promise<SchemaDefinition> {
        const response = await Axios.put<unknown, SchemaDefinition>(`/api/schema/resources/${typeName}`, {
            displayName: resourceDef.displayName
        });

        this.loadSchema(); // reload user schema without waiting for it
        return response;
    }

    @Action({ commit: 'setFullSchema' })
    async deleteResourceDefinition(resourceName: string): Promise<SchemaDefinition> {
        const response = await axios.delete<SchemaDefinition, SchemaDefinition>(`/api/schema/resources/${resourceName}`);

        this.loadSchema(); // reload user schema without waiting for it
        return response;
    }

    @Action({ commit: 'setFullSchema' })
    async deleteFieldDefinition({ typeName, fieldPath }: { typeName: string; fieldPath: string }): Promise<SchemaDefinition> {
        const response = await axios.delete<SchemaDefinition, SchemaDefinition>(`/api/schema/resources/${typeName}/fields/${fieldPath}`);

        this.loadSchema(); // reload user schema without waiting for it
        return response;
    }

    @Action({ commit: 'setFullSchema' })
    async updateViewDefinition({ type, viewDefinition }: { type: string; viewDefinition: string[] }): Promise<SchemaDefinition> {
        const response = await axios.post<string[], SchemaDefinition>(`/api/schema/resources/${type}/viewdefinition`, viewDefinition);

        this.loadSchema(); // reload user schema without waiting for it
        return response;
    }

    @Action({ commit: 'setFullSchema' })
    async updateTenantDefinition({ type, definition }: { type: string; definition: TenantDefinition }): Promise<SchemaDefinition> {
        const response = await axios.put<TenantDefinition, SchemaDefinition>(`/api/schema/tenants/${type}`, definition);

        this.loadSchema(); // reload user schema without waiting for it
        return response;
    }

    @Action({ commit: 'setFullSchema' })
    async deleteTenantDefinition(type: string): Promise<SchemaDefinition> {
        const response = await axios.delete<SchemaDefinition, SchemaDefinition>(`/api/schema/tenants/${type}`);

        this.loadSchema(); // reload user schema without waiting for it
        return response;
    }

    get getResourceDefinition(): (resourceName: string, createCopy?: boolean) => UiResourceDefinition | null {
        return (resourceName: string, createCopy = false): UiResourceDefinition | null => {
            const definition = this.theSchema?.resources[resourceName] ?? null;

            return createCopy ? JSON.parse(JSON.stringify(definition)) : definition;
        };
    }

    get getTenantDefinition(): (tenantName: string) => UiTenantDefinition | null {
        return (tenantName: string): UiTenantDefinition | null => {
            return this.theSchema?.tenants[tenantName] ?? null;
        };
    }

    get getAvailableTypes(): SchemaType[] {
        return Object.keys(this.theSchema ?? {})
            .sort()
            .map((typeName) => ({
                _id: typeName,
                displayName: this.theSchema?.resources[typeName].displayName as string
                // fieldCount: Object.keys(this.theSchema?.[typeName].fields).length
            }));
    }
}
