import { ActionType } from './schema';

export enum ActionStatus {
    Created,
    Queued,
    Running,
    Finished,
    Failed,
    InvalidOrNoTarget
}

export type UiActionPipelineEntry = {
    _id: string;
    actionType: ActionType;
    action: string;
    status: ActionStatus;

    user: string;
    creationDate: number;
    startDate?: number;
    endDate?: number;

    tenantDefinition: string;
    triggeredFor: string;
};

export type JsRenderTemplateObject = {
    _id: string;
    _type: string;
    displayName: string;
    custom: Record<string, unknown>;
    children?: Array<string>;
    parents?: Array<string>;
};

export type JsRenderTemplatingContext = {
    source: JsRenderTemplateObject;

    parents: JsRenderTemplateObject[];
    children: JsRenderTemplateObject[];

    fullSource: JsRenderTemplateObject;

    meta: {
        user: {
            _id: string;
            displayName: string;
            email?: string;
        };
    };
    // relativesByType: Record<string, TemplateObject[]>;
};
