import '@fortawesome/fontawesome-free/css/all.css';
import Vue from 'vue';
import Vuetify, { colors } from 'vuetify/lib';

import de from 'vuetify/src/locale/de';
import en from 'vuetify/src/locale/en';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'fa',
        values: {
            complete: 'fa-check',
            cancel: 'missing:cancel',
            close: 'fa-times',
            delete: 'fa-times',
            clear: 'fa-times',
            success: 'missing:success',
            info: 'missing:info',
            warning: 'missing:warning',
            error: 'fa-exclamation-triangle',
            prev: 'fa-angle-left',
            next: 'fa-angle-right',
            checkboxOn: 'fa-check-square',
            checkboxOff: 'far fa-square',
            checkboxIndeterminate: 'fa-minus-square',
            delimiter: 'missing:delimiter',
            sort: 'fa-chevron-up ml-1',
            expand: 'fa-angle-down',
            menu: 'missing:menu',
            subgroup: 'fa-angle-down',
            dropdown: 'fa-caret-down',
            radioOn: 'missing:radioOn',
            radioOff: 'missing:radioOff',
            edit: 'missing:edit',
            ratingEmpty: 'missing:ratingEmpty',
            ratingFull: 'missing:ratingFull',
            ratingHalf: 'missing:ratingHalf',
            loading: 'fa-sync',
            first: 'missing:first',
            last: 'missing:last',
            unfold: 'missing:unfold',

            'input-text': 'fa-font',
            'input-number': 'fas fa-plus-square',
            'input-date': 'fa-calendar-day',
            'input-identity': 'fa-users',
            'input-list': 'fa-list',
            'input-remote': 'fa-wifi',
            'input-file': 'fa-file',
            'input-boolean': 'fas fa-toggle-off',
            'input-password': 'fa-key',
            'input-link': 'fa-external-link-alt',
            'input-reference': 'fa-link',
            'input-calculated': 'fa-calculator',
            'input-license': 'fa-landmark'
        }
    },
    theme: {
        dark: false,
        options: {
            customProperties: true,
            variations: false
        },
        themes: {
            dark: {
                primary: colors.amber.accent4,
                accent: '#16738F'
            },
            light: {
                primary: '#2B6671',
                secondary: '#4FCBB6',
                accent: '#B164C4'
            }
        }
    },
    lang: {
        current: 'de',
        locales: { de, en }
    }
});
