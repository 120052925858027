/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { DependencyAutoFetchTypes } from './route-guards';
import { RouteConfig } from 'vue-router';

export const adminRoutes: RouteConfig[] = [
    {
        path: '/administration/users',
        name: 'UserManagement',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/administration/user/user-overview.vue'),
        meta: {
            requires: [DependencyAutoFetchTypes.user]
        }
    },
    {
        path: '/administration/users/:_id',
        name: 'UserEdit',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/administration/user/user-edit.vue'),
        meta: {
            requires: [DependencyAutoFetchTypes.user]
        }
    },
    {
        path: '/administration/groups',
        name: 'UserGroupManagement',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/administration/user-group/user-group-overview.vue'),
        meta: {
            requires: [DependencyAutoFetchTypes.usergroup]
        }
    },
    {
        path: '/administration/groups/:_id/:displayName',
        name: 'UserGroupEdit',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/administration/user-group/user-group-edit.vue'),
        meta: {
            requires: [DependencyAutoFetchTypes.fullSchema, DependencyAutoFetchTypes.user, DependencyAutoFetchTypes.usergroup, DependencyAutoFetchTypes.acl]
        }
    },
    {
        path: '/administration/variables',
        name: 'VariablesOverview',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/administration/variables/variable-definition-overview.vue'),
        meta: {
            requires: [DependencyAutoFetchTypes.variables]
        }
    },
    {
        path: '/administration/audit-log',
        name: 'AuditLogOverview',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/administration/audit-log/audit-log-overview.vue'),
        meta: {}
    },
    {
        path: '/profile',
        name: 'UserProfile',
        component: () => import('@/views/administration/user/user-profile.vue')
    }
];
