import axios from 'axios';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from './index';
import { ActionStatus, debounce } from '@raccoon/shared';
import Vue from 'vue';

@Module({ name: 'ActionModule', namespaced: true, dynamic: true, store })
export default class ActionModule extends VuexModule {
    actionStatusCache: Record<string, ActionStatus> = {};
    actionsToFetch: Array<string> = [];

    debouncedLoadFromServer = debounce(this.loadFromServer, 500);

    @Action
    addToLoadQueue(actionId: string): void {
        this.debouncedLoadFromServer = this.debouncedLoadFromServer || debounce(this.loadFromServer, 500);

        if (!this.actionStatusCache[actionId]) {
            this.actionsToFetch.push(actionId);

            this.debouncedLoadFromServer();
        }
    }

    @Mutation
    async loadFromServer() {
        const batchToLoad = this.actionsToFetch;

        if (!batchToLoad.length) {
            return;
        }

        this.actionsToFetch = [];

        // eslint-disable-next-line
        const response = await axios.post<any, Array<{ _id: string; status: ActionStatus }>>(`/api/actions/bulk-status`, {
            actions: batchToLoad
        });

        // const keyedStatus: Record<string, ActionStatus> = {};

        for (const actionStatus of response) {
            // keyedStatus[actionStatus._id] = actionStatus.status;
            Vue.set(this.actionStatusCache, actionStatus._id, actionStatus.status);
        }

        // Object.assign(this.actionStatusCache, keyedStatus);
        //
        // console.log(this.actionStatusCache, keyedStatus);
    }
}
