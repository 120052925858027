import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { initDependencyAutoFetch } from './route-guards';
import { resetIdleTime } from '@/modules/auth';
import { adminRoutes } from './admin-routes';
import { definitionRoutes } from './definition-routes';
import { tenantRoutes } from './tenant-routes';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '',
        component: () => import('@/views/frame.vue'),
        children: [
            {
                path: '/',
                name: 'Root',
                component: () => import('@/views/home.vue')
            },
            ...adminRoutes,
            ...definitionRoutes,
            ...tenantRoutes,
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/login.vue'),
        meta: {
            fullscreen: true
        }
    },

    { path: '*', redirect: '/' }
];

const router = new VueRouter({
    mode: 'hash',
    routes
});

initDependencyAutoFetch(router);
router.afterEach(() => {
    resetIdleTime();
});

export default router;
