import axios from 'axios';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { IdentityDataPermission, UiUserGroup } from '@raccoon/shared';

import store from './index';

@Module({ name: 'UserGroupModule', namespaced: true, dynamic: true, store })
export default class UserGroupModule extends VuexModule {
    userGroups: UiUserGroup[] | null = null;

    @Mutation
    setUserGroups(groups: UiUserGroup[]): void {
        if (groups) {
            this.userGroups = groups;
        }
    }

    @Mutation
    invalidateCache(): void {
        this.userGroups = null;
    }

    @Action({ commit: 'setUserGroups' })
    async loadUserGroups(): Promise<UiUserGroup[] | null> {
        try {
            return await axios.get('/api/usergroups');
        } catch (err) {
            return null;
        }
    }

    @Action
    async getUserGroups(ignoreCache?: boolean): Promise<UiUserGroup[] | null> {
        if (this.userGroups && !ignoreCache) {
            return this.userGroups;
        }

        await this.loadUserGroups();

        return this.userGroups;
    }

    @Action
    async createUserGroup(groupName: string): Promise<UiUserGroup> {
        const createdGroup: UiUserGroup = await axios.post('/api/usergroups/' + groupName);

        if (!this.userGroups) {
            this.userGroups = [];
        }

        this.userGroups?.push(createdGroup);

        return createdGroup;
    }

    @Action({ commit: 'invalidateCache' })
    async updateUserGroup({ _id, group }: { _id: string; group: UiUserGroup & { acl?: IdentityDataPermission } }): Promise<void> {
        await axios.put(`/api/usergroups/${_id}`, group);

        // TODO update existing group / invalidate acl?!
    }

    get getLoadedUserGroupById(): (_id: string) => UiUserGroup | null {
        return (_id: string): UiUserGroup | null => {
            return this.userGroups?.find((group) => group._id === _id) ?? null;
        };
    }
}
