import i18n from '@/plugins/i18n';
import vuetify from '@/plugins/vuetify';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from './index';
import Vue from 'vue';

@Module({ name: 'AppStateModule', namespaced: true, dynamic: true, store })
export default class AppState extends VuexModule {
    funEnabled = process.env.VUE_APP_ENABLE_FUN === 'true';
    sidebarVisible = true;
    isSidebarMini = false;
    activeLanguage = 'de' as string;
    activeTheme = 'light' as 'light' | 'dark';

    @Mutation
    setMiniSidebar(mini: boolean): void {
        this.isSidebarMini = mini;
    }

    @Mutation
    setSidebarVisible(visible: boolean): void {
        this.sidebarVisible = visible;
    }

    @Mutation
    toggleSidebarVisible(): void {
        this.sidebarVisible = !this.sidebarVisible;
    }

    @Mutation
    setLanguage(lang: string): void {
        this.activeLanguage = lang;

        i18n.locale = lang;
        vuetify.framework.lang.current = lang;

        Vue.nextTick(() => {
            i18n.locale = lang;
            vuetify.framework.lang.current = lang;
        });
    }

    @Mutation
    toggleTheme(): void {
        const newTheme = this.activeTheme === 'light' ? 'dark' : 'light';
        this.activeTheme = newTheme;
        vuetify.framework.theme.dark = this.activeTheme === 'dark';
        window.localStorage.setItem('theme', newTheme);
    }

    @Mutation
    setTheme(theme: 'dark' | 'light'): void {
        this.activeTheme = theme;
        vuetify.framework.theme.dark = this.activeTheme === 'dark';
        window.localStorage.setItem('theme', theme);
    }
}
