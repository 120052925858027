export enum SuccessFactorsNotificationState {
    Open = 1,
    Resolved = 2,
    All = Open | Resolved
}

export type UiSuccessFactorsNotification = {
    _id: string;
    state: SuccessFactorsNotificationState;
    errorCode: string;
    text: string;
    lastErrorDate: string;
    foundIn: Record<
        string,
        {
            providerId: string;
            tenantId?: string;
            instanceId?: string;
        }
    >;
};

export type UiSuccessFactorsNotificationk8s = {
    _id: string;
    state: SuccessFactorsNotificationState;
    errorCode: string;
    text: string;
    lastErrorDate: string;
    namespace?: string;
    foundIn: {
        clusterId?: string;
        landscapeId?: string;
        tenantId?: string;
    };
};

export type UiSuccessFactorsLogEntry = {
    _id: string;
    time: string;
    logger: string;
    user: string;
    severity: string;
    text: string;
    exception?: string;
};

export enum SuccessFactorsResponsibility {
    None = 0,
    Operations,
    Consulting
}

export enum SuccessFactorsConsiderFor {
    Both = 0,
    Provider = 1,
    Tenant = 2
}

export type UiSuccessFactorsErrorDefinition = {
    _id: string;
    responsibility: SuccessFactorsResponsibility;
    considerFor: SuccessFactorsConsiderFor;
    regex: Array<string>;
    reoccuranceTime: number;
    reoccuranceAmount: number;
    text: string;
    isRegexOr: boolean;
    sort: number;
};

export type UiSuccessFactorsErrorDefinitionk8s = UiSuccessFactorsErrorDefinition & {
    _type: string;
};
