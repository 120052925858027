<template>
    <v-app>
        <transition name="fade" mode="out-in">
            <router-view class="fill-height"></router-view>
        </transition>

        <notifications />
    </v-app>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'App',
    components: {
        notifications: () => import('@raccoon/ui-vue-commons/src/components/utils/global-notifications.vue')
    }
});
</script>
