import Vue from 'vue';
import Vuex from 'vuex';

import { config } from 'vuex-module-decorators';
import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { initAuthorizationToken, resetIdleTime, setAuthHeader } from '@/modules/auth';

config.rawError = true;

axios.interceptors.request.use(
    (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
        resetIdleTime();
        return setAuthHeader(config);
    }
);

axios.interceptors.response.use(
    (response: AxiosResponse) => {
        return response.data;
    },
    (err) => {
        if (err.response.status === 401) {
            return initAuthorizationToken();
        }

        throw err;
    }
);

Vue.use(Vuex);

const store = new Vuex.Store({});

export { store };
export default store;
