import Vue, { CreateElement, RenderContext, VNode } from 'vue';
import { schema } from '@/store/bundle';
import { FieldPermissions, TenantPermission } from '@raccoon/shared';

type TenantAccessProps = {
    tenantDefinitionName: string;
    permission: TenantPermission;
};
type ResourceFieldProps = {
    resourceName: string;
    fieldPath: string;
    permission: FieldPermissions;
};

export const tenantAccess = Vue.component<TenantAccessProps>('tenant-access', {
    functional: true,
    props: {
        tenantDefinitionName: {
            type: String,
            required: true
        },
        permission: {
            type: Number as () => TenantPermission,
            required: true
        }
    },
    render(createElement: CreateElement, { props, slots }: RenderContext<TenantAccessProps>): VNode | VNode[] {
        if (hasTenantPermission(props.tenantDefinitionName, props.permission)) {
            return slots().default;
        } else {
            return slots()['no-permission'];
        }
    }
});

export const resourceFieldAccess = Vue.component<ResourceFieldProps>('resource-field-access', {
    functional: true,
    props: {
        resourceName: {
            type: String,
            required: true
        },
        fieldPath: {
            type: String,
            required: true
        },
        permission: {
            type: Number as () => FieldPermissions,
            required: true
        }
    },
    render(createElement: CreateElement, { props, slots }: RenderContext<ResourceFieldProps>): VNode | VNode[] {
        if (hasResourceFieldPermission(props.resourceName, props.fieldPath, props.permission)) {
            return slots().default;
        } else {
            return slots()['no-permission'];
        }
    }
});

export function hasTenantPermission(tenantDefinitionName: string, permission: TenantPermission): boolean {
    return ((schema.theSchema?.tenants[tenantDefinitionName]?.permission ?? TenantPermission.Default) & permission) === permission;
}
export function hasResourceFieldPermission(resourceName: string, fieldPath: string, permission: FieldPermissions): boolean {
    return ((schema.theSchema?.resources[resourceName]?.fields[fieldPath]?.permission ?? FieldPermissions.Default) & permission) === permission;
}

export const PermissionMixin = Vue.extend({
    methods: {
        hasTenantPermission: hasTenantPermission,
        hasResourceFieldPermission: hasResourceFieldPermission
    }
});
